<template>
  <div id="app">
    <topNav></topNav>
    <router-view />
  </div>
</template>

<script>
import topNav from "@/components/TopNav.vue";
export default {
  name: "",
  data() {
    return {
      lang: true,
    };
  },
  components: { topNav },
  methods: {},
};
</script>


<style>
#app {
  /* width: 1920px; */
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  background-color: #fafafa;
}
* {
  padding: 0;
  margin: 0;
}
#content {
  width: 1272px;
  overflow: hidden;
  margin: auto;
}
</style>
