export default {
    message: {
        'topNav': {
            service: {
                first: 'SERVICE',
                second: {
                    second_1: 'Dalian Headquarters',
                    second_2: 'Singapore Branch'
                }
            },
            fleet: {
                first: 'FLEET',
                second: {
                    second_1: 'Fos Fleet Details',
                    second_2: 'Fleet Introduction'
                }
            },
            cargo: {
                first: 'CARGO',
                second: {
                    second_1: 'Bulk Cargo Type',
                    second_2: 'Introduction Ships'
                }
            },
            line: {
                first: 'COURSE',
                second: {
                    second_1: 'Course',
                }
            },
            partner: {
                first: 'PARTNER',
                second: {
                    second_1: 'Partner',
                }
            },
            contact: {
                first: 'CONTACT',
                second: {
                    second_1: 'Dalian Headquarters',
                    second_2: 'Singapore Company'
                }
            },
        },
        'index': {
            'index_one': {
                'title_left': 'Customer First,',
                'title_right': ' Reputation First',
                'content': ''
            },
            'index_two': {
                'content': 'FORTUNE OCEAN SHIPPING LIMITED was personally invested by Sun Xingtao and registered in Hong Kong in 2000. Its domestic company is Tangshan Haigang Haixing Shipping Co., Ltd., which was established in 1998. For business needs, FORTUNEOCEAN SHIPPING LIMITED transferred its registered address to the British Virgin Islands (BVI) in 2007. The only shareholder and director of the company are natural persons Sun Xingtao.'
            },
            'index_three': {
                'title_one': 'soybean',
                'content_one': '',
                'title_two': 'coal',
                'content_two': '',
                'title_three': 'steels',
                'content_three': '',
                'title_four': 'iron ore',
                'content_four': '',
            },
            'index_four': {
                'content': "As a ship operator, I have gradually gained a reputation in the international market and gradually rented a large number of bulk carriers such as Panas. As one of the core businesses, relying on the huge import demand for coal, iron ore, grain, and other goods in China before the financial crisis, the company's business has developed rapidly. Taking advantage of market opportunities, the company signed two construction contracts with Rongsheng Heavy Industry for two 176000 ton strait type ships in 2006. At the same time, The ship was also locked in a long-term lease to a large state-owned enterprise, and the two aforementioned ships were delivered one after another in 2010, bringing sustained and considerable profits to the company. Among them, the CAPE SUN ship was repurchased by the lessee in 2014, and the CAPESTAR ship lease has been fulfilled to this day."
            },
            'index_five': {
                'content': 'In 2010, the company signed six Kalsam type bulk carriers with a deadweight of 82000 tons with Guangzhou Longxue Shipyard. Similar to the aforementioned strait type ships, they locked in long-term leases to large state-owned enterprises, and the ships were delivered from 2012 to 2013. Two of the ships were sold under the leases, while the remaining four leases continue to this day.'
            },
            'index_six': {
                'content_left_one': "From 2013 to 2014, the company successively signed 17 new shipbuilding contracts through Mitsubishi, Sumitomo, Itochu, and Marubeni companies in Japan at the shipyards of Changshi, Oshima, Mitsui, and Mingcun. The ships built by the Japanese shipyard have better quality and economy, and the ships have been continuously delivered and used since 2015, until the last one was handed over in early April 2018.",
                'content_left_two': 'In 2017, at a critical turning point in the shipping market from low to high, the company ordered six Karsam type ships with a deadweight tonnage of 82000 at Tianjin Xingang Shipyard at very competitive prices, which will be delivered gradually from 2019 to 2020. At the beginning of 2019, the company resold the six Karsam ships and four ongoing Karsam type ships to China National Bank Leasing.',
                'content_right_fleet_one': 'xingchanghai',
                'content_right_fleet_two': 'xingdehai',
                'content_right_fleet_three': 'xingxihai',
                'content_right_fleet_four': 'xinghehai',
                'content_right_fleet_five': 'xingjihai',
                'content_right_fleet_six': 'xingshouhai',
                'content_right_fleet_seven': 'xingfuhai',
                'content_right_fleet_download_title': 'Download',
                'content_right_fleet_download_content': '',
            },
            'index_seven': {
                'black_one': 'fleet',
                'black_two': 'Tonnage',
                'black_three': 'Number of voyages',
                'black_four': 'Cargo type',
            },
            'index_eight': {
                'content_top': 'The company has an excellent team with years of shipping experience, with team members mainly from Dalian Maritime University and Shanghai Maritime University. They have backgrounds in captain and chief engineer, as well as graduates in shipping management and maritime law. Guided by the business philosophy of "customer first, reputation first", the company has steadily and continuously developed, gradually building itself into an industry leader with a good international reputation and reputation.',
                'content_bottom': 'As of now, the company has 22 ships in operation, including strait type, Karlsam type, super flexible type, flexible type ships, etc; The total carrying capacity is 1.45 million tons, with a total asset value of nearly 700 million US dollars, an annual operating income of over 100 million US dollars, and an annual cargo transportation volume of over 10 million tons. The overall scale is among the top among private shipowners in China.',
            },
            'index_nine': {
                'content': 'The company has maintained long-term friendly cooperative relationships with Bank of Communications, Minsheng Bank, Mitsubishi, Sumitomo, Itochu, and Marubeni in Japan, and has a good cooperation vision and foundation in various fields.'
            },
        },
        'company': {
            'company_dalian': {
                'title': 'DALIAN HEADQUARTERS',
                'content': 'Dalian Hairun Ship Management Co., Ltd. was established in May 2021, located in the beautiful coastal city of Dalian, Liaoning Province. It is a professional ship management company funded by Sun Xingtao and registered with the Dalian Market Management Bureau in Liaoning Province. It is a professional international ship management company that integrates international ship management, ship leasing, ship engineering technology consulting services, and shipping agency.'
            },
            'company_singapore': {
                'title': 'SINGAPORE BRANCH',
                'content': 'In June 2018, the company decided to register and establish a Singaporean company, FORTUNE OCEAN MARINE PTE LTD, dedicated to the charter business of non vessel carriers. The flexible ship leasing department has rented 8 large flexible ships for short distance transportation in Southeast Asia. Since its establishment, it has been responsible for providing transportation services for multiple grain cargo companies, such as Yihai Kerry, Louis Dafu, Xiamen Jianfa Mitsubishi Grain, etc.'
            }
        },
        'fleet': {
            'fleetContent': {
                'text': ''
            },
            'fleetCard': {
                'fleetCard_one': '',
                'fleetCard_two': '',
                'fleetCard_three': '',
                'fleetCard_four': '',
            }
        },
        'cargo': {
            'cargoType': {
                'cargoType_1': {
                    'name': 'Mineral/Stone',
                    'content': 'A mineral is a naturally occurring solid, usually formed by inorganic processes. There are several known mineral types, including bauxite, granite, limestone, sand, etc.'
                },
                'cargoType_2': {
                    'name': 'coal',
                    'content': 'Coal is one of the most important primary fossil fuels, a solid carbon rich material mainly used for general electricity and steel production.'
                },
                'cargoType_3': {
                    'name': 'Cereals and Sugar',
                    'content': 'Cereals and sugar are used for human and animal consumption, providing food security for the world. Cereals include barley, corn, corn, oats, rice, soybeans, wheat, etc.'
                },
                'cargoType_4': {
                    'name': 'Forest products',
                    'content': 'Forestry products can be used for several different purposes, such as biofuels (sawdust particles) and construction wood. Wood chips are usually made from compacted sawdust.'
                },
                'cargoType_5': {
                    'name': 'building materials',
                    'content': 'Building materials include clinker, slag and gypsum. They combine to form cement, which is a bonding material used in construction and civil engineering.'
                },
                'cargoType_6': {
                    'name': 'soybean',
                    'content': 'Soybean is an ideal high-quality plant protein food. Eating more soybeans and soy products is beneficial for human growth, development, and health.'
                },
                'cargoType_7': {
                    'name': 'steel',
                    'content': 'Steel is transported in various forms, such as plates/slabs, coils, and bars/wires. Steel is widely used in the construction of global infrastructure and industry, as well as everything from sewing needles to oil tankers.'
                },
                'cargoType_8': {
                    'name': 'iron ore',
                    'content': 'The main use of iron ore is to produce steel. Steel is used to manufacture beams, paper clips, tools, and thousands of other items used in cars and buildings.'
                },
                'cargoType_9': {
                    'name': 'coke',
                    'content': 'Petroleum coke petroleum coke is a by-product of oil refining. Most of petroleum coke is used for energy production in cement industry and other industries.'
                },
                'cargoType_10': {
                    'name': 'salt',
                    'content': 'Salt is a mineral that is very important for human and animal health, as well as for industry. It is used for food seasoning, highway deicing, and chemical production.'
                }
            },
            'cargoBottom': {
                'cargoBottom_1': {
                    'title': '',
                    'content': ''
                },
                'cargoBottom_2': {
                    'title': '',
                    'content': ''
                },
            }
        },
        'contact': {
            contact_1: {
                'company': 'Dalian headquarters',
                'name': 'Mr Che',
                'Tel': '0086-411-83705060',
                'Fax': '0086-411-83600766',
                'E.mail': 'ops@fortuneocean.cn',
                'address': 'No.311 Zhongshan Road,Shahekou District,Dalian,P.R.China.'
            },
            contact_2: {
                'company': 'Singapore Company',
                'name': 'Mr Sun',
                'Tel': '+65-69569153',
                'E.mail': 'Sunqilong@fortuneocean.sg',
                'address': '182cecil street,#17-55/99 frasers tower, Singapore 069547.'
            }
        },
        'partner': {
            partner_1: 'Bank of Communications',
            partner_2: 'Ito Tadasho Co., Ltd',
            partner_3: 'China Minsheng Bank',
            partner_4: 'Mitsubishi Heavy Industries',
            partner_5: 'Marubeni Corporation',
            partner_6: 'China Shipbuilding Group',
            partner_7: 'COSCO Shipping Group',
            partner_8: 'CMES SHIPPING',
            partner_9: 'COFCO',
            partner_10: 'BAOWU',
            partner_11: 'CARGILL',
            partner_12: 'Louis Dreyfus Company',
        },
        'news': {
            'newsHome': 'On May 12th, Hengli Shipbuilding (Dalian) Co., Ltd., a subsidiary of Hengli Heavy Industry Group, held a construction contract with Haixing Shipping Co., Ltd. for four 82000 dwt bulk carriers',
            'newsIndex': 'On May 12th, Hengli Shipbuilding (Dalian) Co., Ltd., a subsidiary of Hengli Heavy Industry Group, held a construction contract with Haixing Shipping Co., Ltd. for four 82000 deadweight ton bulk carriers. Hengli Shipbuilding (Dalian) Co., Ltd. signed a contract with An Jinxiang, General Manager of Hengli Group and Chairman of Hengli Heavy Industry Group at Shanghai Shipbuilding Research and Design Institute, Lu Kun, General Manager of Hengli Heavy Industry Group, and Sun Xingtao, Chairman of Haixing Shipping Co., Ltd, Zhou Zhiyong, Vice President of Shanghai Shipbuilding Research and Design Institute, as well as numerous domestic and foreign industry units and partners, attended the signing ceremony. The Kamsarmax 82000 dwt bulk carrier built this time is the mainstream bulk carrier type in the shipping market. This series of bulk cargo has a length of 229 meters, a width of 32.26 meters, a depth of 20.35 meters, a designed draft of 12.2 meters, a carrying capacity of 82000 tons, and a designed speed of 14.0 knots. It has significant characteristics such as "green, environmental protection, energy conservation, and safety", and is the latest generation of ship types that meet the third stage of EEDI.'

        }
    }
}