<template>
  <div class="box">
    <div class="text">
      ©2022 FORTUNE OCEAN SHIPPING LIMITED I 京ICP备09008062号-3 I 京公网安备
      11010802037369号
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped>
.box {
  height: 59px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.box .text {
  /* margin-top: 20px; */
  height: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #9da1a8;
  text-align: center;
  line-height: 59px;
  /* zoom: 0.8; */
}
</style>