var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"leftText"},[_vm._v(" "+_vm._s(_vm.$t("message.index.index_nine.content"))+" ")]),_c('div',{staticClass:"blueLine"})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"rightBox",style:({
        backgroundImage: 'url(' + require('../assets/images/Img10.png') + ')',
      })}),_c('div',{staticClass:"rightBox",style:({
        backgroundImage: 'url(' + require('../assets/images/Img11.png') + ')',
      })}),_c('div',{staticClass:"rightBox",style:({
        backgroundImage: 'url(' + require('../assets/images/Img12.png') + ')',
        backgroundSize: '70%',
        backgroundPosition: '50% 30%',
      })}),_c('div',{staticClass:"rightBox",style:({
        backgroundImage: 'url(' + require('../assets/images/Img13.png') + ')',
      })}),_c('div',{staticClass:"rightBox",style:({
        backgroundImage: 'url(' + require('../assets/images/Img14.png') + ')',
      })}),_c('div',{staticClass:"rightBox",style:({
        backgroundImage: 'url(' + require('../assets/images/Img15.png') + ')',
      })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }