export default {
    message: {
        'topNav': {
            service: {
                first: '服务范围',
                second: {
                    second_1: '大连总部',
                    second_2: '新加坡分公司'
                }
            },
            fleet: {
                first: '船队信息',
                second: {
                    second_1: 'FOS舰队明细',
                    second_2: '舰队介绍'
                }
            },
            cargo: {
                first: '载货类型',
                second: {
                    second_1: '散货类型',
                    second_2: '船只介绍'
                }
            },
            line: {
                first: '航线总览',
                second: {
                    second_1: '航线总览',
                }
            },
            partner: {
                first: '合作伙伴',
                second: {
                    second_1: '合作伙伴',
                }
            },
            contact: {
                first: '联系方式',
                second: {
                    second_1: '大连总部',
                    second_2: '新加坡分公司'
                }
            },
        },
        'index': {
            'index_one': {
                'title_left': '客 户 至 上',
                'title_right': '信 誉 第 一',
                'content': ''
            },
            'index_two': {
                'content': 'FORTUNE OCEAN SHIPPING LIMITED 由孙兴涛个人投资于 2000 年在香港注册成立，其境内公司为成立于 1998 年的唐山海港海兴航运有限公司，为经营需要，FORTUNEOCEAN SHIPPING LIMITED 于 2007年将注册地转移至英属维尔京群岛（BVI），公司的唯一股东和董事均为自然人孙兴涛。'
            },
            'index_three': {
                'title_one': '大豆',
                'content_one': '',
                'title_two': '煤炭',
                'content_two': '',
                'title_three': '钢材',
                'content_three': '',
                'title_four': '铁矿石',
                'content_four': '',
            },
            'index_four': {
                'content': '作为船舶经营人在国际市场上逐步闯出名声，陆续期租进大批的巴拿这种散货船的核心业务之一，依托金融危机以前，我国对煤炭、铁矿石、粮食等的巨大进口需求，公司的经营业务进行的风生水起，瞅准市场契机，公司在 2006 年与熔盛重工签订了 2 艘载重吨176000 吨的海峡型船的建造合同，与此同时，又将该船舶长期锁定租约，租予大型国有企业，上述两艘船舶也在 2010 年陆续交付，为公司带来持续可观的利润，其中的CAPE SUN轮于 2014 年被租家买回，CAPESTAR 轮租约一直履行至今。'
            },
            'index_five': {
                'content': '2010 年公司又与广州龙穴造船厂签订了6艘载重吨82000吨的卡尔萨姆型散货船，与上述海峡型船相似的手法，分别锁定了长期租约给大型国有企业，船舶陆续在 2012 年至 2013 年交付，其中两艘船被带着租约出售，其余四艘租约持续至今。'
            },
            'index_six': {
                'content_left_one': '2013-14 年，公司陆续通过日本的三菱、住友、伊藤忠、丸红商社在日本的常石、大岛、三井、名村船厂签订了十七艘新造船合同，日本船厂建造的船舶具由更好的质量和经济性，船舶已从 2015 年开始陆续交付使用，至 2018 年 4 月初最后一艘交接完。',
                'content_left_two': '2017 年，在航运市场由低向高转折的关键点上，公司又以非常有竞争性的价格在天津新港造船厂订造了6艘载重吨为82000的卡尔萨姆型船，将于 2019-2020 年陆续交付。2019 年初，公司又将该 6条卡尔萨姆船和四艘在航的卡尔萨姆型船转卖给了国银租赁。',
                'content_right_fleet_one': '兴昌海',
                'content_right_fleet_two': '兴德海',
                'content_right_fleet_three': '兴喜海',
                'content_right_fleet_four': '兴合海',
                'content_right_fleet_five': '兴吉海',
                'content_right_fleet_six': '兴寿海',
                'content_right_fleet_seven': '兴福海',
                'content_right_fleet_download_title': '下载',
                'content_right_fleet_download_content': '企业介绍',
            },
            'index_seven': {
                'black_one': '舰队',
                'black_two': '吨数',
                'black_three': '航行次数',
                'black_four': '载货类型',
            },
            'index_eight': {
                'content_top': '公司拥有一个优秀的具有多年航运经验的团队，团队成员主要来自于大连海事大学，上海海事大学，既有船长，轮机长出身的，又有航运管理和海商法专业毕业的。公司在“客户至上，信誉第一”的经营理念指导下，稳步持续发展，逐步把公司建设成了有良好国际声誉和名望的行业佼佼者。',
                'content_bottom': '截至目前，公司拥有在航船舶22艘，船型包括海峡型、卡尔萨姆型、超灵便型、灵便型船等；总计运力载重吨145万吨，资产总值近7亿美元，年营运收入超1亿美元，年货物运输量超1000万吨，整体规模在国内的民营船东中名列前茅。',
            },
            'index_nine': {
                'content': '公司与交通银行、民生银行、日本的三菱、住友、伊藤忠、丸红商社保持着长期友好的合作关系，在各个领域，都有很良好的合作愿景和基础。'
            },
        },
        'company': {
            'company_dalian': {
                'title': '大连总部',
                'content': '大连海润船舶管理有限公司成立于 2021 年 5 月，位于美丽的海滨城市辽宁省大连市，是由孙兴涛出资成立的专业船舶管理公司，公司经辽宁省大连市市场管理局批准注册。是一家国际船舶管理、船舶租赁、船舶工程技术咨询服务、航运代理等为一体的专业国际船舶管理公司。'
            },
            'company_singapore': {
                'title': '新加坡分公司',
                'content': '2018年6月，公司决定注册组建新加坡公司 FORTUNE OCEAN MARINE PTE LTD 致力于无船承运人租船业务。灵便型船型租船部门短期租入8条大灵便船舶，用于东南亚短途运输成立以来已经为多家粮食货主公 司负责承运服务，比如益海嘉里，路易达芙，厦门建发三菱谷物等。'
            }
        },
        'fleet': {
            'fleetContent': {
                'text': ''
            },
            'fleetCard': {
                'fleetCard_one': '',
                'fleetCard_two': '',
                'fleetCard_three': '',
                'fleetCard_four': '',
            }
        },
        'cargo': {
            'cargoType': {
                'cargoType_1': {
                    'name': '矿物/矿石',
                    'content': '矿物质是一种天然存在的固体，通常由无机过程形成。已知的矿物种类有数干种，包括铝土矿、花岗岩、石灰石、沙子等。'
                },
                'cargoType_2': {
                    'name': '煤',
                    'content': '煤炭是最重要的初级化石燃料之一，是一种固体富碳材料，主要用于一般电力和钢铁生产。'
                },
                'cargoType_3': {
                    'name': '谷物和糖',
                    'content': '谷物和糖用于人类和动物消费，为世界提供粮食安全。谷物包括大麦、玉米、玉米、燕麦、大米、大豆、小麦等。'
                },
                'cargoType_4': {
                    'name': '林业品',
                    'content': '林业产品可用于几种不同的目的，例如生物燃料（木屑颗粒）和建筑木材。木屑颗粒通常由压实的锯末制成。'
                },
                'cargoType_5': {
                    'name': '建材',
                    'content': '建筑材料包括水泥熟料、炉渣和石膏。它们结合在一起成为水泥，水泥是建筑和土木工程施工中使用的粘合材料。'
                },
                'cargoType_6': {
                    'name': '大豆',
                    'content': '大豆是一种理想的优质植物蛋白食物，多吃大豆及豆制品，有利于人体生长发育和健康。'
                },
                'cargoType_7': {
                    'name': '钢',
                    'content': '钢材以多种形式运输，例如板材/板坯、卷材和棒材/线材。钢铁广泛用于建设世界基础设施和工业，以及从缝纫针到油轮的一切。'
                },
                'cargoType_8': {
                    'name': '铁矿',
                    'content': '铁矿石的主要用途是生产钢铁。钢铁用于制造汽车、建筑物中使用的横梁、回形针、工具和数千种其他物品。'
                },
                'cargoType_9': {
                    'name': '石油焦',
                    'content': '石油焦是炼油时产生的副产品。大部分石油焦用于水泥工业等行业的能源生产。'
                },
                'cargoType_10': {
                    'name': '盐',
                    'content': '盐是一种对人类和动物健康以及工业非常重要的矿物质。它用于食品调味，高速公路除冰和化学品生产。'
                }
            },
            'cargoBottom': {
                'cargoBottom_1': {
                    'title': '',
                    'content': ''
                },
                'cargoBottom_2': {
                    'title': '',
                    'content': ''
                },
            }
        },
        'contact': {
            contact_1: {
                'company': '大连总部联系方式',
                'name': 'Mr Che',
                'Tel': '0086-411-83705060',
                'Fax': '0086-411-83600766',
                'E-mail': 'ops@fortuneocean.cn',
                'address': 'No.311 Zhongshan Road,Shahekou District,Dalian,P.R.China.'
            },
            contact_2: {
                'company': '新加坡公司联系方式',
                'name': 'Mr Sun',
                'Tel': '+65-69569153',
                'E-mail': 'Sunqilong@fortuneocean.sg',
                'address': '182cecil street,#17-55/99 frasers tower, Singapore 069547.'
            }
        },
        'partner': {
            partner_1: '中国交通银行',
            partner_2: '伊藤忠商株式会社',
            partner_3: '中国民生银行',
            partner_4: '日本三菱重工',
            partner_5: '丸红株式会社',
            partner_6: '中国船舶集团',
            partner_7: '中远海运集团',
            partner_8: '招商轮船集团',
            partner_9: '中粮集团',
            partner_10: '中国宝武钢铁集团',
            partner_11: 'CARGILL',
            partner_12: 'Louis Dreyfus Company',
        },
        'news': {
            'newsHome': '5月12日，恒力重工集团旗下恒力造船（大连）有限公司举行与海兴航运有限公司4艘82000载重吨散货船建造合同',
            'newsIndex': '5月12日，恒力重工集团旗下恒力造船（大连）有限公司举行与海兴航运有限公司4艘82000载重吨散货船建造合同，恒力造船（大连）有限公司与上海船舶研究设计院恒力集团总经理、恒力重工集团董事长安锦香，恒力重工集团总经理卢堃，海兴航运有限公司董事长孙兴涛，上海船舶研究设计院副院长周志勇以及国内外众多行业单位和合作伙伴出席签约仪式。此次建造的卡尔萨姆型（Kamsarmax）82000载重吨散货船是航运市场主流的散货船船型。该系列散货船长229米，型宽32.26米，型深20.35米，设计吃水12.2米，载重量82000吨，设计航速14.0节，具有“绿色、环保、节能、安全”等显著特点，是满足EEDI第三阶段的最新一代船型。'

        }
    }
};