<template>
  <div class="box" @click="news()">
    <div class="imgBox">
      <slot name="Img"></slot>
    </div>
    <div class="content">
      <div class="content_text content_text_C" ref="newsTitle">
        {{ $t("message.news.newsHome") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexAssembly2",
  data() {
    return {};
  },
  components: {},
  methods: {
    news() {
      this.$router.push("/news");
    },
  },
  updated() {
    console.log(this.$refs["newsTitle"]);
    if (this.$i18n.locale == "cn") {
      this.$refs["newsTitle"].className = "content_text content_text_C";
    } else {
      this.$refs["newsTitle"].className = "content_text content_text_E";
    }
  },
};
</script>

<style scoped>
.box {
  width: 1272px;
  height: 846px;
  box-shadow: 0px 3px 6px 1px rgba(72, 142, 255, 0.1);
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  position: relative;
  background-image: url(../assets/images/news_bg.jpeg);
  background-size: 100%;
  background-repeat: no-repeat;
}
.box .content {
  width: 1272px;
  height: 72px;
  background: #232323;
  border-radius: 0px 0px 12px 12px;
  opacity: 1;
  position: absolute;
  bottom: 0;
}
.content_text {
  background: linear-gradient(180deg, #2172f6 0%, rgba(42, 115, 235, 0) 100%);
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  width: 1272px;
  height: 72px;
}
.content_text_C {
  line-height: 72px;
}
.content_text_E {
  line-height: 34px;
}
</style>