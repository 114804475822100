<template>
  <div class="topNav">
    <div class="topNav_title" @click="backHome"></div>
    <div class="topSwitch" @click="changeLangEvent()">
      <div class="topSwitch_left_Y" ref="topSwitch_left">中</div>
      <div class="topSwitch_right_F" ref="topSwitch_right">英</div>
      <div class="topSwitch_bg_L" ref="topSwitch_bg"></div>
    </div>

    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      text-color="#4C4C4C"
      router
    >
      <el-submenu
        index="1"
        class="el-menu-items"
        popper-class="aaamenu"
        :popper-append-to-body="true"
      >
        <template slot="title">
          {{ $t("message.topNav.service.first") }}
        </template>
        <el-menu-item index="/service" default-active="#1f70f5">
          {{ $t("message.topNav.service.second.second_1") }}
        </el-menu-item>
        <el-menu-item index="/service">
          {{ $t("message.topNav.service.second.second_2") }}
        </el-menu-item>
      </el-submenu>
      <el-submenu
        index="2"
        popper-class="aaamenu"
        :popper-append-to-body="true"
      >
        <template slot="title">
          {{ $t("message.topNav.fleet.first") }}
        </template>
        <el-menu-item index="/fleet">
          {{ $t("message.topNav.fleet.second.second_1") }}
        </el-menu-item>
        <el-menu-item index="/fleet">
          {{ $t("message.topNav.fleet.second.second_2") }}
        </el-menu-item>
      </el-submenu>
      <el-submenu
        index="3"
        popper-class="aaamenu"
        :popper-append-to-body="true"
      >
        <template slot="title">
          {{ $t("message.topNav.cargo.first") }}
        </template>
        <el-menu-item index="/cargo">
          {{ $t("message.topNav.cargo.second.second_1") }}
        </el-menu-item>
        <el-menu-item index="/cargo">
          {{ $t("message.topNav.cargo.second.second_2") }}
        </el-menu-item>
      </el-submenu>
      <el-submenu
        index="4"
        popper-class="aaamenu"
        :popper-append-to-body="true"
      >
        <template slot="title">
          {{ $t("message.topNav.line.first") }}
        </template>
        <el-menu-item index="/line">
          {{ $t("message.topNav.line.second.second_1") }}
        </el-menu-item>
      </el-submenu>
      <el-submenu
        index="5"
        popper-class="aaamenu"
        :popper-append-to-body="true"
      >
        <template slot="title">
          {{ $t("message.topNav.partner.first") }}
        </template>
        <el-menu-item index="/partners">
          {{ $t("message.topNav.partner.second.second_1") }}
        </el-menu-item>
      </el-submenu>
      <el-submenu
        index="6"
        popper-class="aaamenu"
        :popper-append-to-body="true"
      >
        <template slot="title">
          {{ $t("message.topNav.contact.first") }}
        </template>
        <el-menu-item index="/contact">
          {{ $t("message.topNav.contact.second.second_1") }}
        </el-menu-item>
        <el-menu-item index="/contact">
          {{ $t("message.topNav.contact.second.second_2") }}
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      isShowMenu: false,
      activeIndex: "1",
    };
  },
  components: {},
  methods: {
    showMenu() {
      this.isShowMenu = !this.isShowMenu;
      console.log(this.isShowMenu);
    },
    handleSelect(key, keyPath) {},
    backHome() {
      this.$router.push("/");
      this.$router.go(0);
    },
    changeLangEvent() {
      if (this.$i18n.locale === "cn") {
        this.$i18n.locale = "en"; //关键语句
        // console.log(this.$i18n.locale);
        this.$refs["topSwitch_left"].className = "topSwitch_left_F";
        this.$refs["topSwitch_right"].className = "topSwitch_right_Y";
        this.$refs["topSwitch_bg"].className = "topSwitch_bg_R";
      } else {
        this.$i18n.locale = "cn"; //关键语句
        this.$refs["topSwitch_left"].className = "topSwitch_left_Y";
        this.$refs["topSwitch_right"].className = "topSwitch_right_F";
        this.$refs["topSwitch_bg"].className = "topSwitch_bg_L";

        // console.log(this.$i18n.locale);
      }
      // this.$confirm("确定切换语言吗?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   // type: "warning",
      // })
      //   .then(() => {
      //     if (this.$i18n.locale === "cn") {
      //       this.$i18n.locale = "en"; //关键语句
      //       console.log(this.$i18n.locale);
      //     } else {
      //       this.$i18n.locale = "cn"; //关键语句
      //       console.log(this.$i18n.locale);
      //     }
      //   })
      //   .catch(() => {
      //     console.log("catch");
      //     this.$message({
      //       type: "info",
      //     });
      //   });
    },
  },
};
</script>

<style  lang='less'>
.topSwitch {
  width: 82px;
  height: 29px;
  // background-color: #bfa;
  float: left;
  margin-top: 16px;
  border-radius: 14px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.topSwitch:hover {
  cursor: pointer;
}
.topSwitch .topSwitch_left_F,
.topSwitch .topSwitch_right_F {
  flex: 1;
  text-align: center;
  line-height: 29px;
  color: #666666;
  font-size: 12px;
  z-index: 2;
}
.topSwitch .topSwitch_left_Y,
.topSwitch .topSwitch_right_Y {
  flex: 1;
  text-align: center;
  line-height: 29px;
  color: #fff;
  z-index: 2;
  font-size: 12px;
}
.topSwitch .topSwitch_bg_L {
  position: absolute;
  width: 41px;
  height: 29px;
  background-color: #1f70f5;
  border-radius: 14px;
  transform: translate(0%);
  transition: all 0.2s;
  z-index: 1;
}
.topSwitch .topSwitch_bg_R {
  position: absolute;
  width: 41px;
  height: 29px;
  background-color: #1f70f5;
  border-radius: 14px;
  transform: translate(100%);
  transition: all 0.2s;

  z-index: 1;
}
.topNav {
  line-height: 47.5px;
  position: relative;
  text-align: end;
  background-color: #fff;
  overflow: hidden;
}
.topNav_title {
  width: 480px;
  height: 60px;
  float: left;
  background-image: url(../assets/images/navTitle-1.png);
  // background-color: #bfa;
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: 20px 3px;
}
.hiddenMenu {
  background-color: pink;
  text-align: end;
  line-height: 47.5px;
}
.menuShow {
  width: 100%;
  position: absolute;
  background-color: blue;
  top: 47.5px;
  left: 0;
}

.el-submenu .el-submenu__title:hover {
  background-color: #1f70f5 !important;
  color: #fff !important;
}
.el-menu-item:hover {
  background-color: #1f70f5 !important;
  color: #fff !important;
}
::v-deep .el-menu--horizontal .el-menu--popup .el-menu-item {
  // background-color: red;
  // display: block;
  color: red;
}

.el-menu {
  float: right;
}
.el-menu .aaamenu {
  background-color: pink !important;
  flex-wrap: 700 !important;
}
.aaamenu .el-menu--popup {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px 5px;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  background-color: #1f70f5;
  color: #fff !important;
  border: 0 !important;
}
.el-menu.el-menu--horizontal {
  border: 0 !important;
}
</style>